/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";
import css from "@emotion/css";
import { keyframes } from "@emotion/core";
import TextAssistant24 from "../Base/text/TextAssistant24";
// import NextPageArrow from "../Navigation/NextPageArrow";
import { mediaQueries } from "../../configs/_configSite";
import TitleMain from "../Base/titles/TitleMain";

// Images
import ImgPhoneCarrierIntroCircles from "../Images/PhoneCarrier/ImgPhoneCarrierIntroCircles";
import ImgCircle1 from "../../images/phone-carrier/phone-carrier-intro-circle-1.png";
import ImgCircle2 from "../../images/phone-carrier/phone-carrier-intro-circle-2.png";
import ImgCircle3 from "../../images/phone-carrier/phone-carrier-intro-circle-3.png";
import FeaturesPhoneCarrierSlider from "../Features/FeaturesPhoneCarrierSlider";
import VideoPLayerFullScreen from "../VideoPlayers/VideoPlayerFullScreen";
import VideoFile from "../../videos/mountain-video-background.mp4";

const ContainerMain = styled(Div100vh)`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ContainerIntroText = styled.div`
  position: absolute;
  display: inline-table;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0;
  width: 500px;
  right: calc(50vw - 550px);
  margin: 0 auto;
  top: 32vh;
  bottom: 0;
  animation: ${fadeIn} 0.5s ease-in 2s forwards;

  @media (max-width: ${mediaQueries.lg}px) {
    transform: scale(0.9);
    right: calc(50vw - 525px);
  }

  @media (max-width: ${mediaQueries.md}px) {
    max-width: 100%;
    width: 500px;
    top: 55vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 15px 30px;

    @media (max-width: ${mediaQueries.xs}px) {
      transform: scale(0.8);
      width: 375px;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const ContainerCircles = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: ${mediaQueries.sm}px) {
    display: none;
  }
`;

const CircleImg = styled.div`
  width: 100px;
  height: 100px;
`;

const data = {
  circleImages: [ImgCircle1, ImgCircle2, ImgCircle3]
};

export default () => {
  return (
    <ContainerMain>
      <FeaturesPhoneCarrierSlider />
      <ContainerIntroText>
        <TitleMain
          css={css`
            color: #ffffff;
            text-align: center;
          `}
        >
          Carriers of Care
        </TitleMain>
        <TextAssistant24
          css={css`
            font-size: 16px !important;
          `}
        >
          Wayuu people and their work of art woven bags...more to follow
        </TextAssistant24>
        <ContainerCircles>
          {data.circleImages.map(img => (
            <CircleImg
              css={css`
                background: url(${img}) center / cover;
              `}
            />
          ))}
        </ContainerCircles>
      </ContainerIntroText>
      {/* <NextPageArrow text="Product" /> */}
      <VideoPLayerFullScreen video={VideoFile} />
      <ImgPhoneCarrierIntroCircles />
    </ContainerMain>
  );
};
