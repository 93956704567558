import React from "react";
import { css } from "@emotion/core";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { mediaQueries } from "../../../configs/_configSite";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: {
            eq: "phone-carrier/phone-carrier-intro-circles-accent.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        loading="eager"
        css={css`
          position: absolute !important;
          width: 800px;
          height: 800px;

          right: calc(50% - 700px);
          top: 5vh;
          opacity: 0.8;
          transform: rotate(-2deg);

          @media (max-width: 1350px) {
            right: calc(50% - 650px);
            height: 700px;
            width: 700px;
            top: 10vh;
          }

          @media (max-width: ${mediaQueries.lg}px) {
            right: calc(50% - 585px);
            height: 625px;
            width: 625px;
            top: 13vh;
          }

          @media (max-width: ${mediaQueries.md}px) {
            margin: 0 auto;
            left: -1000px;
            right: -1000px;
            height: 600px;
            width: 600px;
            top: 42vh;
          }
        `}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
);
