/* eslint-disable no-plusplus */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "@emotion/styled";

import css from "@emotion/css";
// import Accent from "../../images/phone-carrier/phone-carrier-accent.png";
import TitleMain from "../Base/titles/TitleMain";
import TitleSmall from "../Base/titles/TitleSmall";

import Product1 from "../../images/phone-carrier/phone-carrier-product-1.jpg";
import Product2 from "../../images/phone-carrier/phone-carrier-product-2.png";
import { mediaQueries } from "../../configs/_configSite";
import { LinkPurchaseExt } from "../Base/links/LinkPurchase";

// background: url(${Accent}) center bottom / contain no-repeat;
const Container = styled.section`
  z-index: 1;
  position: absolute;
  height: 400px;
  width: 500px;
  top: 20vh;
  left: 10vw;
  text-align: center;

  @media (max-width: ${mediaQueries.lg}px) {
    transform: scale(0.9);
    left: 15px;
  }
  @media (max-width: ${mediaQueries.md}px) {
    transform: scale(0.85);
    top: 5vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 500px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    transform: scale(0.65);
    transform-origin: top center;
    width: 400px;
    left: -20px;
    top: 10vh;
  }
`;

const ContainerProduct = styled.div`
  position: relative;
  left: 0;
  right: 0;
  height: 250px;
  width: 250px;
  margin: 15px auto 25px auto;
`;

const data = [
  {
    title: "Victor Over Triple",
    subtitle: "Crossbody Bag",
    img: Product1,
    link: "https://ananavestige.com"
  },
  {
    title: "We Walk By Faith",
    subtitle: "Crossbody Bag",
    img: Product2,
    link: "https://ananavestige.com"
  }
];

export default () => {
  const [i, setI] = useState(0);

  return (
    <Container>
      <svg
        onClick={() => setI(i === 0 ? i + 1 : i - 1)}
        css={css`
          position: absolute;
          z-index: 2;
          left: 0;
          top: 50%;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            opacity: 0.75;
          }
        `}
        width="24"
        height="50"
        viewBox="0 0 24 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.438943 26.1601C-0.146314 25.4927 -0.146315 24.5073 0.438942 23.8399L20.7977 0.624923C21.8981 -0.629852 24 0.131581 24 1.78498L24 48.215C24 49.8684 21.8981 50.6299 20.7977 49.3751L0.438943 26.1601Z"
          fill="white"
        />
      </svg>

      <svg
        onClick={() => setI(i === 0 ? i + 1 : i - 1)}
        css={css`
          position: absolute;
          z-index: 2;
          right: 0;
          top: 50%;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            opacity: 0.75;
          }
        `}
        width="24"
        height="50"
        viewBox="0 0 24 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.5611 26.1601C24.1463 25.4927 24.1463 24.5073 23.5611 23.8399L3.2023 0.624923C2.10191 -0.629852 1.90735e-06 0.131581 1.90735e-06 1.78498L0 48.215C0 49.8684 2.10191 50.6299 3.2023 49.3751L23.5611 26.1601Z"
          fill="white"
        />
      </svg>

      <>
        <TitleMain
          css={css`
            color: white;
            font-size: 2.5rem;

            @media (max-width: ${mediaQueries.md}px) {
              font-size: 2.25rem;
            }

            @media (max-width: ${mediaQueries.xs}px) {
              font-size: 2rem;
            }
          `}
        >
          {data[i].title}
        </TitleMain>
        <TitleSmall
          css={css`
            margin-top: -15px;
            @media (max-width: ${mediaQueries.md}px) {
              font-size: 1.25rem;
            }
          `}
        >
          {data[i].subtitle}
        </TitleSmall>
        <ContainerProduct
          css={css`
            background: url(${data[i].img}) no-repeat center/contain;
          `}
        />
        <LinkPurchaseExt href={data[i].link}>Purchase</LinkPurchaseExt>
      </>
    </Container>
  );
};
